import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import HomeLayout from '../components/HomeLayout';
import { Box, Container, Typography } from '@mui/material';
import Footer from '../components/Footer';
import ListGame from '../components/ListGame';
import HomeSlider from '../components/HomeSlider';
import { Helmet } from 'react-helmet';
import { locales } from '../locales';
import axios from 'axios';
import moment from 'moment';
import { getAttemptsTimePromoCode, getRandomComment } from '../redux/slices/user.slice';
import Like from '../components/Like';
import { getArticleList, resetArticleList } from '../redux/slices/article.slice';
import { Link } from 'react-router-dom';
import { PhotoCamera } from '@mui/icons-material';
const MainPage = ({ meta }) => {
  const dispatch = useDispatch();
  const [wpPosts, setWpPosts] = useState([]);
  const {
    getArticleListState: { data: articleList },
  } = useSelector((state) => state.article);
  useEffect(() => {
    axios.get('https://blog.donate-gold.ru/wp-json/wp/v2/posts?categories=10&_embed').then((res) => {
      setWpPosts(res.data);
    });
    dispatch(getAttemptsTimePromoCode());
    dispatch(getRandomComment());
    dispatch(getArticleList({ isHomeArticle: true }));
    return () => {
      dispatch(resetArticleList());
    };
  }, []);
  const {
    getAttemptsTimePromoCodeState: { data: getAttemptsTimePromoCodeData },
    getRandomCommentState: { data: randomComment },
  } = useSelector((state) => state.user);
  return (
    <HomeLayout isFullContainer>
      {meta}
      <Container style={{ maxWidth: 'none', padding: '0', marginLeft: '-16px', width: 'calc(100% + 32px)' }}>{/* <HomeSlider /> */}</Container>
      <Container>
        <Box sx={{ marginTop: '20px' }}>
          <a href="https://donate-steam.ru" target="_blank" style={{ textDecoration: 'none', width: 'min-content', display: 'inline-block' }}>
            <Box sx={{ pl: '5px', display: 'flex', alignItems: 'center', cursor: 'pointer', userSelect: 'none', color: '#000 !important', width: 'min-content' }}>
              <img src="/steam-img.png" style={{ width: '60px', display: 'block', marginRight: '5px' }} />
              <Box>
                <Box sx={{ mb: '2px', fontWeight: '600 !important', textDecoration: 'underline', textUnderlineOffset: '1px', whiteSpace: 'nowrap' }}>Пополнение Steam</Box>
                <Box sx={{ fontSize: '15px', textDecoration: 'none !important', whiteSpace: 'nowrap' }}>Любой аккаунт</Box>
              </Box>
            </Box>{' '}
          </a>
          <Box sx={{ marginBottom: '20px', display: 'grid', gridGap: '20px', gridTemplateColumns: { xs: 'repeat(1,250px)', mob: 'repeat(3, minmax(0,250px))' }, alignItems: 'center', justifyContent: { xs: 'center', mob: 'space-between' }, width: '100%' }}></Box>
          <Box sx={{ display: 'grid', alignItems: 'center', padding: '15px 0px 20px 15px', backgroundColor: '#fff', borderRadius: '10px', marginBottom: '20px', position: 'relative', gridTemplateColumns: '64px 1fr auto' }}>
            {randomComment && (
              <>
                {randomComment?.user?.avatar ? (
                  <Box
                    component={'img'}
                    src={randomComment?.avatar || `${process.env.REACT_APP_SERVER_DOMAIN}/images/${randomComment?.user?.avatar}`}
                    sx={{ gridRow: '1/3', width: '44px', height: '44px', borderRadius: '50%', objectFit: 'cover', display: 'block', marginRight: { xs: '15px', mob: '20px' } }}
                  />
                ) : (
                  <Box sx={{ border: '1px solid #999', position: 'relative', gridRow: '1/3', width: '44px', height: '44px', borderRadius: '50%', objectFit: 'cover', display: 'block', marginRight: { xs: '15px', mob: '20px' } }}>
                    <PhotoCamera sx={{ fontSize: '19px', color: '#666', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', position: 'absolute' }} />
                  </Box>
                )}

                <Box sx={{ gridRow: { xs: '1/3', mob: '1/2' }, marginBottom: '3px', fontSize: '15px', color: '#000', fontWeight: '600 !important' }}>{randomComment?.name || randomComment?.user?.name}</Box>
                <Box sx={{ marginTop: { xs: '12px', mob: '0' }, gridColumn: { xs: '1/3', mob: '2/3' }, gridRow: { xs: '3/4', mob: '2/3' }, fontSize: '14px', color: '#000' }}>{randomComment?.text}</Box>
                <Box sx={{ alignSelf: 'end', gridRow: { mob: '1/3', xs: '4/5' }, gridColumn: { xs: '3/4', mob: '3/4' } }}>
                  <Like defaultLikes={randomComment?.like} commentId={randomComment?.id} />
                </Box>
              </>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ backgroundColor: '#fff', padding: '30px 10px 30px 10px', borderRadius: '8px' }}>
              <ListGame />
            </Box>

            <Box sx={{ width: 'calc(100% - 40px)', marginTop: '30px', padding: '25px 20px 120px 20px', borderRadius: '8px', backgroundColor: '#fff' }}>
              {' '}
              {articleList?.rows?.map((itemPost) => (
                <Link style={{ textDecoration: 'none' }} to={`articles/${itemPost?.slug}`}>
                  <Box sx={{ paddingBottom: '25px', borderBottom: '1px solid #f1f1f1', display: 'grid', gridTemplateColumns: { xs: '1fr', mob: '275px 1fr' }, columnGap: '10px' }}>
                    <img src={`${process.env.REACT_APP_SERVER_DOMAIN}/images/${itemPost?.image}`} style={{ width: '275px', height: '156px' }} />
                    <Box>
                      <Box sx={{ marginTop: '13px', marginBottom: '5px', color: '#898989', fontSize: { xs: '10px', mob: '12px' } }}>{moment(itemPost?.createdAt).format('DD.MM.YY')}</Box>
                      <Box sx={{ marginBottom: '7px', fontSize: { xs: '14px', mob: '20px' }, fontWeight: '600 !important', color: '#000' }}>{itemPost?.name}</Box>
                      <Box sx={{ color: '#4c4c4c', fontSize: { xs: '12px', mob: '14px' }, display: '-webkit-box', '-webkit-line-clamp': '2', ' -webkit-box-orient': 'vertical', overflow: 'hidden' }}>{itemPost?.shortDesc}</Box>
                    </Box>
                  </Box>
                </Link>
              ))}
            </Box>
            {/* <Box sx={{ marginTop: '30px', marginBottom: '100px', display: 'grid', gridGap: '20px', gridTemplateColumns: { xs: 'repeat(1,250px)', mob: 'repeat(3, minmax(0,250px))' }, alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              {getAttemptsTimePromoCodeData?.map((itemPromoCode) => {
                return (
                  <Box>
                    <Box sx={{ marginLeft: '15px', marginBottom: '8px', fontSize: '15px', color: '#a2a2a2' }}>{moment(itemPromoCode?.date).format('DD.MM.YY в HH:mm').toString()}</Box>
                    <Box sx={{ color: '#000', padding: '20px 30px 15px 20px', backgroundColor: '#fff', borderRadius: '8px' }}>
                      <Box sx={{ fontSize: '12px', textDecoration: 'none', marginBottom: '13px' }}>Поймал рыбку</Box>
                      <Box>{itemPromoCode?.user?.name}</Box>
                    </Box>
                  </Box>
                );
              })}
            </Box> */}
          </Box>
        </Box>
      </Container>
    </HomeLayout>
  );
};

export default MainPage;

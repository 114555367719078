import { Checkbox, Container, IconButton, MenuItem, Select, Switch } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import HomeLayout from '../components/HomeLayout';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import { blue, red } from '@mui/material/colors';
import { Box, CircularProgress, DialogActions, DialogContent, DialogContentText, Input, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { currencyFormat } from '../utils/currencyFormat';

import { useForm, Controller } from 'react-hook-form';
import { checkUser, checkUserReset, getUser, sendPackageInChat } from '../redux/slices/user.slice';
import axios from 'axios';
import { createTransaction, createTransactionReset } from '../redux/slices/transaction.slice';
import DrawerAppBar from '../components/MainLayout';
import GameCard from '../components/GameCard';
import '../styles/GamePage.scss';
import PaymentModal from '../components/PaymentModal';
import AcceptModal from '../components/AcceptModa';
import PaymentCardModal from '../components/PaymentCardModal';
import PaymentGameCardModal from '../components/PaymentGameCardModal';
import SelectPackageModal from '../components/SelectPackageModal';
import { AccessTimeOutlined, AutoAwesomeOutlined, Bolt, HelpOutlineOutlined, ShieldOutlined, SwitchAccessShortcutAddOutlined, VerifiedUserOutlined } from '@mui/icons-material';
import OutsideClickHandler from 'react-outside-click-handler';
import SelectRadio from '../components/SelectRadio';
import Chatra from '@chatra/chatra';
import { locales } from '../locales';
import { NumericFormat } from 'react-number-format';
import GameTabs from '../components/GameTabs';
import Loader from '../components/Loader';
const GamePageComponent = ({ data }) => {
  const dispatch = useDispatch();
  const {
    getPackageState: { data: packageList },
  } = useSelector((state) => state.package);
  const {
    createTransactionState: { loading: transLoading, data: transData, error: transError },
  } = useSelector((state) => state.transaction);
  const {
    checkUserState: { data: checkData, loading: checkLoading, error: checkError },
    getUserState: { data: userData },
    sendPackageInChatState: { data: sendPackageInChatData },
  } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const defaultValues = {
    isBoost: true,
    packageId: null,
    playerId: '',
    serverId: data?.serverList ? data?.serverList[0]?.serverId : '',
  };
  const {
    setValue,
    handleSubmit,
    formState: { errors },
    control,
    register,
    getValues,
    watch,
  } = useForm({ defaultValues });
  console.log(errors);
  const [step, setStep] = useState(1);
  const [tabs, setTabs] = useState([]);
  const [isAgree, setIsAgree] = useState(false);
  const [activeCard, setActiveCard] = useState();
  const [disableCheck, setDisableCheck] = useState(false);
  const [disableDonate, setDisableDonate] = useState(data?.checkPlayer);
  const [repeatCheck, setRepeatCheck] = useState(false);
  const handleAgree = () => setIsAgree(!isAgree);
  React.useEffect(() => {
    if (checkError?.error === 'PROBLEM_WITH_TOKEN') {
      dispatch(checkUserReset());
      dispatch(getUser());
    }
  }, [checkError]);
  React.useEffect(() => {
    if (transData) {
      setValue('packageId', packageList?.[0]?.code);
      setValue('playerId', null);
      setValue('serverId', data?.serverList ? data?.serverList[0]?.serverId : '');
      dispatch(checkUserReset());
    }
  }, [transData]);
  useEffect(() => {
    if (sendPackageInChatData) {
      Chatra('openChat');
    }
  }, [sendPackageInChatData]);

  React.useEffect(() => {
    if (packageList && packageList?.length !== 0) {
      setValue('packageId', packageList?.[0]?.code);
    }
  }, [packageList]);
  const onSubmitCheck = () => {
    onCheckPlayer();
  };
  const onCheckPlayer = () => {
    if (repeatCheck) {
      setRepeatCheck(false);
      setDisableCheck(false);
      setDisableDonate(true);
    } else {
      const playerId = getValues('playerId');
      const serverId = getValues('serverId');
      setLastServer(serverId);
      if (playerId) {
        dispatch(checkUser({ playerId, serverId: data?.id == 24 || data?.id == 22 ? 1 : serverId, typeGameId: data?.id }));
      }
    }
  };
  useEffect(() => {
    return () => {
      dispatch(checkUserReset());
    };
  }, []);

  const onSubmit = (packageId) => {
    const playerIdData = getValues('playerId');
    const serverIdData = getValues('serverId');
    const boostData = getValues('isBoost');
    if (playerIdData) {
      dispatch(createTransaction({ isBoost: boostData, playerId: playerIdData, serverId: data?.id == 24 ? 20002 : data?.id == 22 ? 1 : serverIdData, packageId, typeGameId: data?.id }));
    }
  };
  const [lastServer, setLastServer] = useState(null);
  const [openAccept, setOpenAccept] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openSelectPackage, setOpenSelectPackage] = useState(false);
  const handleCloseSelectPackage = () => {
    // setOpenSelectPackage(false);
    setStep(1);
  };
  const handleOpenSelectPackage = () => {
    setStep(2);
    // setOpenSelectPackage(true);
  };
  const handleCloseAccept = () => {
    setOpenAccept(false);
  };
  const handleClosePaymentCardModal = () => {
    setOpenPaymentModal(false);
  };

  const handleNext = () => {
    onSubmit(selectedGameCode);
  };
  useEffect(() => {
    if (!checkData && checkError) {
      setDisableDonate(true);
      setDisableCheck(false);
    } else if (checkData && !checkError) {
      setDisableDonate(false);
      setDisableCheck(true);
      setRepeatCheck(true);
    }
  }, [checkData, checkError]);
  const [errorText, setErrorText] = useState(null);
  const [selectedGameCode, setselectedGameCode] = useState(null);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [showHelp, setShowHelp] = useState(false);
  const [policyLight, setPolicyLight] = useState(false);
  useEffect(() => {
    return () => {
      // dispatch(resetCheck)
    };
  }, []);
  const watchServerId = watch('serverId');
  const watchBoost = watch('isBoost');
  const watchPlayer = watch('playerId');
  console.log(watchBoost);
  console.log(watch());

  React.useEffect(() => {
    if (transError?.error === 'PACKAGE_NOT_ACTIVE') {
      dispatch(sendPackageInChat({ packageId: transError?.package?.id, type: 'package-over' }));
      dispatch(createTransactionReset());
      setStep(1);
    } else if (transError) {
      const err =
        transError?.error === 'PACKAGE_NOT_ACTIVE' ? (
          <Box sx={{ fontSize: { mobile: '20px', xs: '16px' } }}>{locales[process.env.REACT_APP_LOCALE].packageNotAvailable}</Box>
        ) : transError?.error === 'BALANCE_ERROR' ? (
          locales[process.env.REACT_APP_LOCALE].insufficientFunds
        ) : transError?.error === 'ACCOUNT_NOT_FOUND' ? (
          <div>{locales[process.env.REACT_APP_LOCALE].accountNotFound}</div>
        ) : (
          locales[process.env.REACT_APP_LOCALE].unexpectedError
        );
      setErrorText(err);

      dispatch(createTransactionReset());
    }
  }, [transError]);
  const packList = [...(packageList?.filter((pack) => pack?.typeGameId === data?.id && !!(watchBoost ? pack?.priceNEcards : pack?.priceMoogold)) || [])]?.sort(function (x, y) {
    return x.order - y.order;
  });

  useEffect(() => {
    if (packageList?.length) {
      const tabsList = [];

      const withMoogold = [...(packageList?.filter((pack) => pack?.typeGameId === data?.id && pack?.priceMoogold) || [])];
      const withNeCards = [...(packageList?.filter((pack) => pack?.typeGameId === data?.id && pack?.priceNEcards) || [])];
      if (withMoogold?.length) {
        tabsList.push({
          tooltip: 'Поступит в течение ~5 мин.',
          label: 'Лучшая цена',
          value: false,
        });
        setValue('isBoost', false);
      }
      if (withNeCards?.length) {
        tabsList.push({
          tooltip: 'Поступит моментально',
          label: (
            <Box sx={{ transform: 'translateY(-4.5px)', marginLeft: '-7px', fontWeight: '600 !important' }}>
              <Bolt sx={{ marginBottom: '-6px', transition: 'all 0.3s' }} /> С бустом
            </Box>
          ),
          value: true,
        });
        setValue('isBoost', withMoogold?.length ? false : true);
      }
      setTabs(tabsList);
    }
  }, [packageList]);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };
  return (
    <Box>
      {data.metaTags}
      <Box className="game" style={{ ...(data?.id == 10 && { paddingTop: '30px' }) }}>
        {/* <Box
          sx={{
            display: { sx: 'block', mob: 'none' },
            top: '60px',
            backgroundImage: `url("${data?.mobileBackground || data?.background}")`,
            width: '100vw',
            height: '100vh',
            zIndex: 0,
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%',
            backgroundRepeat: 'no-repeat',
            position: 'sticky',
            marginTop: '-100vh',
          }}></Box> */}
        <Container>
          <Box sx={{ marginBottom: { mobile: '-135px', xs: '-205px' } }}>{data?.background && <Box component={'img'} src={data?.background} sx={{ width: '100%', height: { xs: '250px', mob: '280px' }, objectFit: 'cover', dispay: 'block', marginBottom: '25px' }}></Box>}</Box>
          <div className="game__content">
            <Box className="game__title" sx={{ marginLeft: { mobile: '15px', xs: '10px' }, marginRight: { mobile: '15px', xs: '10px' } }}>
              {data?.name}
            </Box>
            {data?.id != 10 && (
              <Box sx={{ marginLeft: { mobile: '15px', xs: '10px' }, display: 'flex', alignItems: 'start', flexDirection: { mobile: 'row', xs: 'column' }, rowGap: '10px', columnGap: '20px' }}>
                <Box sx={{ padding: '2px 10px', backgroundColor: '#fff', borderRadius: '50px', height: '25px', fontWeight: '600 !important', display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                  <ShieldOutlined sx={{ color: '#1565c0', fontSize: '18px', marginRight: '3px' }} /> Официальное пополнение
                </Box>
                <Box sx={{ padding: '2px 10px', backgroundColor: '#fff', borderRadius: '50px', height: '25px', fontWeight: '600 !important', display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                  <AccessTimeOutlined sx={{ color: 'rgb(237, 188, 11)', fontSize: '18px', marginRight: '3px' }} />
                  {data?.secondBlockText || 'Моментальная доставка'}
                </Box>
                <Box sx={{ padding: '2px 10px', backgroundColor: '#fff', borderRadius: '50px', height: '25px', fontWeight: '600 !important', display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                  <AutoAwesomeOutlined sx={{ color: '#c62828', fontSize: '18px', marginRight: '3px' }} /> Бонусная валюта
                </Box>
              </Box>
            )}

            <Box
              sx={{
                fontSize: { mobile: '16px', xs: '12px' },
                marginTop: '40px',
                backgroundColor: '#fff',
                borderRadius: '12px',
                padding: { mobile: '20px', xs: '15px' },
                '& ul': {
                  margin: 0,
                },
              }}>
              {data?.desc}
            </Box>
            <Box sx={{ marginTop: '10px', fontSize: '24px', marginBottom: '10px' }}>{data?.id == 10 ? 'Расчет' : `Купить паки для ${data?.fullName}`}</Box>
            <Box
              onClick={() => {
                scrollToBottom();
              }}
              className=""
              sx={{ marginBottom: '30px', textDecoration: 'underline', textDecorationThickness: '1px', textUnderlineOffset: '3px', color: 'primary.main', userSelect: 'none', cursor: 'pointer', textDecorationStyle: 'dashed' }}>
              Задонатить
            </Box>
            <GameTabs list={tabs} withBoost={data?.switchTypeDonate} value={watchBoost} setValue={setValue} />
            <Box sx={{ backgroundColor: '#fff', borderRadius: '0 12px 12px 12px', padding: '25px 20px 25px 20px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="game__card-grid">
                  {packList?.map((packageItem, index) => (
                    <GameCard
                      key={packageItem?.id}
                      full={packageItem?.code == 1111 || packageItem?.code == 2222}
                      disabled={disableDonate}
                      active={activeCard === index}
                      img={data?.pacakgeImageList[index]}
                      price={watchBoost ? packageItem?.priceNEcards : packageItem?.priceMoogold}
                      value={packageItem?.code}
                      isChatPackage={packageItem?.isChatPackage}
                      gameId={packageItem?.typeGameId}
                      label={packageItem?.name}
                      discount={packageItem?.discount}
                      onClickCard={() => {
                        if (userData) {
                          if (!disableDonate) setActiveCard(index);
                        } else {
                          navigate('/auth');
                        }
                      }}
                      // onClick={() => {
                      //   if (isAgree) {
                      //     setselectedGameCode(packageItem?.code);
                      //     setOpenAccept(true);
                      //   } else {
                      //     document.getElementById('agree-block').scrollIntoView({ behavior: 'smooth' });
                      //   }
                      // }}
                      onClickCart={() => {
                        if (packageItem?.isChatPackage) {
                          dispatch(sendPackageInChat({ packageId: packageItem?.id, type: 'package-custom' }));
                        } else {
                          setSelectedPackageId(packageItem?.id);
                          setOpenPaymentModal(true);
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            </Box>
            {!data.noCheckPlayer && (
              <>
                <Box sx={{ marginTop: '20px', marginBottom: '20px', fontSize: '24px' }}>Задонатить</Box>

                <Box sx={{ backgroundColor: '#fff', borderRadius: '12px', padding: `20px 20px 20px 20px` }} ref={messagesEndRef}>
                  {/* {data?.switchTypeDonate && (
                    <div style={{ marginBottom: '16px' }}>
                      {' '}
                      <div class="custom-radio-label">{locales[process.env.REACT_APP_LOCALE].swtichTypeDonateLabel}</div>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '50px', display: 'flex', alignItems: 'center', transition: 'all 0.3s', opacity: watchBoost ? 0.5 : 1 }}>
                          <Box
                            style={{ transition: 'all 0.3s', WebkitMaskImage: `url(/turtle.svg)`, WebkitMaskSize: 'contain', WebkitMaskRepeat: 'no-repeat', height: '15px', backgroundColor: watchBoost ? '#000000de' : '#5d4037', marginRight: '6px', width: '22px', display: 'block' }}
                            src="/turtle.svg"></Box>{' '}
                          <div>Off</div>
                        </Box>
                        <Controller name="isBoost" control={control} render={({ field: { onChange, value } }) => <Switch checked={value} onChange={onChange} />} />
                        <Box sx={{ width: '46px', display: 'flex', alignItems: 'center', ml: -0.5, opacity: watchBoost ? 1 : 0.4, transition: 'all 0.3s' }}>
                          <Bolt sx={{ transition: 'all 0.3s', color: watchBoost ? '#ffd54f' : '#000000de' }} />
                          <div>On</div>
                        </Box>
                      </Box>
                      <Box sx={{ fontSize: '12px' }}>{!watchBoost ? 'Доставка 1-30 мин, цена -5%' : 'Моментальная доставка'}</Box>
                    </div>
                  )} */}
                  {errorText ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: { xs: '20px 0', mob: '30px 0' } }}>
                      <img src={'/error2.png'} width={'130px'} />

                      <Box
                        sx={{
                          '& br': {
                            display: { xs: 'none', mob: 'inline-block' },
                          },
                          paddingTop: '15px',
                          zIndex: '100000',
                          textAlign: 'center',
                          fontSize: { xs: '17px', mob: '20px' },
                          whiteSpace: { xs: 'normal', mob: 'nowrap' },
                        }}>
                        {errorText}
                      </Box>
                      <Button
                        variant="outlined"
                        sx={{
                          borderColor: '#616161 !important',
                          color: '#616161 !important',
                          '&:hover': {
                            background: 'transparent',
                          },
                          marginTop: { xs: '20px', mob: '30px' },
                        }}
                        onClick={() => {
                          // setErrorText(null);
                          // setStep(1);
                          window.location.reload(false);
                        }}>
                        Закрыть
                      </Button>
                    </Box>
                  ) : transData ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: { xs: '20px 0', mob: '30px 0' } }}>
                      <img src={'/success.png'} width={'130px'} />

                      <Box
                        sx={{
                          '& br': {
                            display: { xs: 'none', mob: 'inline-block' },
                          },
                          paddingTop: '15px',
                          zIndex: '100000',
                          textAlign: 'center',
                          fontSize: { xs: '17px', mob: '20px' },
                          whiteSpace: { xs: 'normal', mob: 'nowrap' },
                        }}>
                        {locales[process.env.REACT_APP_LOCALE].donateSuccess}
                      </Box>
                      <Button
                        variant="outlined"
                        sx={{ marginTop: { xs: '30px', mob: '40px' } }}
                        onClick={() => {
                          window.location.reload(false);
                          // dispatch(createTransactionReset());
                          // setStep(1);
                        }}>
                        Готово
                      </Button>
                    </Box>
                  ) : transLoading ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '30px 0' }}>
                      <Loader noOverlay />
                      <Box
                        sx={{
                          paddingTop: '50px',
                          zIndex: '100000',
                          textAlign: 'center',
                          fontSize: { xs: '20px', mob: '22px' },
                          whiteSpace: { xs: 'normal', mob: 'nowrap' },
                          width: { xs: '262px', mob: 'auto' },
                        }}>
                        {locales[process.env.REACT_APP_LOCALE].yourDonateSending}
                      </Box>
                    </div>
                  ) : step == 1 ? (
                    <>
                      <Box className="" sx={{ ...(data.id == 5 || (data?.isSelectServer && data?.serverList) ? { mt: { xs: 1, sm: 0 }, display: 'flex', alignItems: { xs: 'center', mob: 'stretch' }, flexDirection: 'column' } : {}) }}>
                        {!data?.noServer && (
                          // <></>
                          <div style={{ ...(data.id === 5 && { fontSize: '18px' }), ...(data?.isSelectServer && !data?.serverList && { marginBottom: '8px' }) }} class="custom-radio-label">
                            {data.id == 5 ? locales[process.env.REACT_APP_LOCALE].inputServer : data?.titleServerInput || locales[process.env.REACT_APP_LOCALE].selectServer}
                          </div>
                        )}

                        {!data?.noServer && (
                          <>
                            {
                              data?.isSelectServer && data?.serverList ? (
                                <Box>
                                  <FormControl>
                                    <Controller
                                      render={({ field }) => (
                                        <Select
                                          MenuProps={{
                                            style: {
                                              maxHeight: 300,
                                            },
                                          }}
                                          size="small"
                                          sx={{ marginTop: '8px', width: '210px' }}
                                          {...field}>
                                          {data?.serverList?.map((serverItem) => (
                                            <MenuItem value={serverItem?.serverId}>{serverItem?.name}</MenuItem>
                                          ))}
                                        </Select>
                                      )}
                                      name={'serverId'}
                                      control={control}
                                      defaultValue={watchServerId}
                                    />
                                  </FormControl>
                                </Box>
                              ) : data?.serverList ? (
                                <>
                                  <FormControl component="fieldset">
                                    {/* <FormLabel component="legend">{data.id == 5 ? locales[process.env.REACT_APP_LOCALE].inputServer : locales[process.env.REACT_APP_LOCALE].selectServer}</FormLabel> */}
                                    <Controller
                                      rules={{ required: true }}
                                      control={control}
                                      name="serverId"
                                      render={({ field }) => (
                                        <RadioGroup row {...field}>
                                          {data?.serverList?.map((serverItem) => (
                                            <FormControlLabel value={serverItem?.serverId} control={<Radio />} label={serverItem?.name} />
                                          ))}
                                        </RadioGroup>
                                      )}
                                    />
                                  </FormControl>
                                </>
                              ) : (
                                <Controller
                                  control={control}
                                  rules={{ required: true }}
                                  name="serverId"
                                  render={({ field }) => (
                                    <TextField
                                      label={data?.labelZone}
                                      helperText={data?.helperTextZone}
                                      sx={{ background: '#fff', width: '210px' }}
                                      size="small"
                                      // class="check-id-input"
                                      {...field}
                                      onChange={(event) => {
                                        const newVal = event.target.value;
                                        if (data.id === 5 || data.id === 19) {
                                          field.onChange(event.target.value);
                                        }
                                      }}
                                      style={{ maxWidth: '263.2px' }}
                                      type={data.id == 19 ? 'text' : 'number'}
                                      disabled={checkLoading || disableCheck}
                                      autoComplete="off"
                                    />
                                  )}
                                />
                              )
                              // <SelectRadio selectedItem={watchServerId} active={data.isSelectServer} list={data?.serverList}>
                              //   <div class="custom-radio" style={{ opacity: checkLoading || disableCheck ? '0.7' : '1' }}>
                              //     {data?.serverList?.map((serverItem) => (
                              //       <label>
                              //         <input {...register('serverId', { required: true })} type="radio" value={serverItem?.serverId} disabled={checkLoading || disableCheck} />

                              //         <span>{serverItem?.name}</span>
                              //       </label>
                              //     ))}
                              //   </div>
                              // </SelectRadio>
                            }
                          </>
                        )}
                      </Box>{' '}
                      {!data.noCheckPlayer && (
                        <>
                          <Box className="" sx={{ mt: { xs: 1, sm: 0 }, display: 'flex', alignItems: { xs: 'center', mob: 'stretch' }, flexDirection: 'column' }}>
                            <div className="check-id-box">
                              <OutsideClickHandler
                                onOutsideClick={() => {
                                  setShowHelp(false);
                                }}>
                                <div className="check-id-label">
                                  <span>{data?.titleInput}</span>
                                  {/* <div style={{ position: 'relative' }}>
                              <IconButton
                                onClick={() => {
                                  setShowHelp(!showHelp);
                                }}
                                disableRipple
                                sx={{ p: 0, ml: 1, mb: '2px' }}>
                                <HelpOutlineOutlined sx={{ fontSize: '20px', color: '#e2ba7e' }} />
                              </IconButton>
                              <img style={{ position: 'absolute', bottom: '150%', right: '-100px', maxHeight: '180px', transition: 'opacity 0.3s, visibility 0.3s', ...(!showHelp && { visibility: 'hidden', opacity: '0' }) }} src={data?.helpImage} />
                            </div> */}
                                </div>
                              </OutsideClickHandler>

                              <Box sx={{ marginBottom: '8px', display: 'flex', flexDirection: 'column' }}>
                                <Controller
                                  control={control}
                                  rules={{ required: true }}
                                  name="playerId"
                                  render={({ field }) => (
                                    <NumericFormat
                                      customInput={TextField}
                                      label={data?.label}
                                      helperText={data?.helperText}
                                      error={errors?.playerId}
                                      size="small"
                                      sx={{
                                        marginTop: '4px',
                                        '& .MuiFormHelperText-root': {
                                          color: '#000',
                                        },
                                        background: '#fff',
                                        width: '210px',
                                      }}
                                      {...field}
                                      onChange={(event) => {
                                        const newVal = event.target.value;
                                        field.onChange(event.target.value);
                                        if (data.id === 2 && newVal?.length >= 1) {
                                          const firstServerId = newVal.substring(0, 1);
                                          if (firstServerId === '6') {
                                            setValue('serverId', 'America');
                                          } else if (firstServerId === '7') {
                                            setValue('serverId', 'Europe');
                                          } else if (firstServerId === '8') {
                                            setValue('serverId', 'Asia');
                                          } else if (firstServerId === '9') {
                                            setValue('serverId', 'TW, HK, MO');
                                          }
                                        }
                                      }}
                                      type={'text'}
                                      disabled={checkLoading || disableCheck}
                                      autoComplete="off"
                                    />
                                  )}
                                />
                                {data?.checkPlayer && (
                                  <Button variant="contained" onClick={handleSubmit(onSubmitCheck)} disabled={checkLoading} sx={{ marginTop: '10px', height: '40px', fontSize: '18px', opacity: checkLoading ? '0.7' : '1', minWidth: '210px', cursor: checkLoading ? 'auto' : 'pointer' }}>
                                    {' '}
                                    {repeatCheck ? locales[process.env.REACT_APP_LOCALE].inputAgain : data?.checkText}
                                  </Button>
                                  // <button class="check-id-btn" >

                                  // </button>
                                )}
                              </Box>
                              {/* <label style={{ display: 'flex', alignItems: 'start', fontSize: '12px', backgroundColor: policyLight ? 'rgba(255, 205, 210,0.6)' : 'transparent', transition: 'background-color 0.5s' }}>
                              {' '}
                              <Checkbox sx={{ marginLeft: '-10px', marginTop: '-10px', display: 'block' }} onClick={handleAgree} checked={isAgree} />
                              <span> {data?.privacyContent}</span>
                            </label> */}

                              {/* <span>{data?.privacyContent}</span> */}
                              {/* <label style={{ backgroundColor: policyLight ? '#ad2305' : 'transparent', transition: 'background-color 0.5s' }} class="accept-checkbox" id="agree-block">
                          <input type="checkbox" name="accept" onClick={handleAgree} checked={isAgree} />
                        </label> */}
                            </div>

                            <div style={{ height: data?.checkPlayer ? '130px' : '22px', display: 'flex', marginRight: 'auto', justifyContent: 'center', alignItems: 'center', position: 'relative', ...(checkData && !checkError && { marginTop: '-5px' }) }}>
                              {checkLoading ? (
                                <div style={{ transform: 'translate(60px,32px)' }}>
                                  {' '}
                                  <Loader noOverlay size={50} />
                                  {/* <div class="loader-custom"></div> */}
                                </div>
                              ) : !checkData && !checkError && !checkLoading ? (
                                <></>
                              ) : checkData && !checkError ? (
                                <div>
                                  {checkData?.image && <img style={{ borderRadius: '8px', background: 'white', width: '120px', position: 'absolute', left: 0, top: '10px' }} src={checkData?.image} />}

                                  <div style={{ paddingLeft: checkData?.image ? '130px' : '0px', display: 'flex', flexDirection: 'column', alignItems: 'start', marginRight: 'auto' }}>
                                    <div className="check-id-label">{locales[process.env.REACT_APP_LOCALE].ourNick}</div>
                                    <Box className="" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '20px', mb: '4px', mt: '-4px' }}>
                                      <b> {checkData?.nickname}</b>
                                      <img src="/check.png" style={{ marginLeft: '6px' }} />
                                    </Box>{' '}
                                    {data?.id == 1 && (
                                      <Box className="" sx={{ flexDirection: 'column', display: 'flex', fontSize: '20px', mt: '2px' }}>
                                        <b> {checkData?.id}</b>
                                      </Box>
                                    )}
                                    {data?.id == 3 && checkData?.device == 'app_store' && (
                                      <Box className="" sx={{ flexDirection: 'column', display: 'flex', fontSize: '20px', transform: ' translateY(10px)', backgroundColor: '#ad2305' }}>
                                        <b> {locales[process.env.REACT_APP_LOCALE].donateOnlyAndroid}</b>
                                      </Box>
                                    )}
                                    {data?.id === 2 && (
                                      <Box className="" sx={{ flexDirection: 'column', display: 'flex', fontSize: '20px', mt: '2px' }}>
                                        <b> {checkData?.id.substring(0, 1) === '6' ? 'America' : checkData?.id.substring(0, 1) === '7' ? 'Europe' : checkData?.id.substring(0, 1) === '8' ? 'Asia' : checkData?.id.substring(0, 1) === '9' ? 'TW, HK, MO' : ''}</b>
                                      </Box>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column', margin: '0 auto', marginTop: '50px' }}>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ whiteSpace: 'nowrap', color: 'error.main', fontWeight: '600', fontSize: '20px' }}>{locales[process.env.REACT_APP_LOCALE].idNotFound}</Typography>
                                    <SearchOffIcon sx={{ ml: '4px', mb: '0px', color: 'error.main', fontSize: '30px' }} />
                                  </div>
                                </div>
                              )}
                              <Button
                                // color="success"
                                variant="contained"
                                disabled={(data?.checkRequired === false ? data?.checkRequired : disableDonate) || (data?.id == 3 && checkData?.device == 'app_store') || checkLoading}
                                // class="check-id-btn finish-donate"
                                sx={{
                                  '&:disabled': {
                                    background: 'rgba(0, 0, 0, 0.10) !important',
                                    color: 'rgba(0, 0, 0, 0.3)  !important',
                                  },
                                }}
                                style={{
                                  background: '#fed100',
                                  color: '#000',
                                  maxWidth: '210px',
                                  width: '210px',
                                  fontSize: '18px',
                                  marginLeft: '0px',
                                  marginTop: '10px',
                                  position: 'absolute',
                                  ...(!disableDonate || checkData ? { bottom: checkData?.image || checkData?.device == 'app_store' ? '-50px' : '-30px' } : { top: '0px' }),
                                  left: 0,
                                }}
                                onClick={() => {
                                  if (userData) {
                                    const playerIdData = getValues('playerId');
                                    if (playerIdData) {
                                      handleOpenSelectPackage();
                                    }
                                  } else {
                                    navigate('/auth');
                                  }
                                }}>
                                {locales[process.env.REACT_APP_LOCALE].donate}
                              </Button>
                            </div>
                            <Box sx={{ color: '#747474', fontSize: '10px', ...(((checkData && !checkError) || !data?.checkPlayer) && { marginTop: '60px' }) }}>{data?.privacyContent}</Box>
                          </Box>
                        </>
                      )}
                    </>
                  ) : step == 2 ? (
                    <>
                      <SelectPackageModal
                        tabs={tabs}
                        setValueForm={setValue}
                        playerId={watchPlayer}
                        server={data?.serverList?.find?.((server) => server?.serverId == watchServerId)?.name ?? watchServerId}
                        isBoost={watchBoost}
                        gameId={data?.id}
                        open={openSelectPackage}
                        onClose={handleCloseSelectPackage}
                        onNext={(selectPackage) => {
                          if (selectPackage) {
                            // setselectedGameCode(selectPackage);
                            onSubmit(selectPackage);
                          }
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </>
            )}
          </div>
        </Container>{' '}
      </Box>
      {data?.bottomDesc && (
        <Container>
          <Box sx={{ marginTop: '20px', fontSize: '24px', marginBottom: '10px' }}>Информация</Box>
          <Box sx={{ backgroundColor: '#fff', borderRadius: '12px', padding: '25px 25px 25px 25px', marginBottom: '60px' }}>
            <div className="game__desc">{data?.bottomDesc}</div>
          </Box>
        </Container>
      )}{' '}
      {/* <AcceptModal isBoost={watchBoost} open={openAccept} selectedPackage={selectedGameCode} text={locales[process.env.REACT_APP_LOCALE].acceptDonate} typeGameId={data?.id} onClose={handleCloseAccept} onNext={handleNext} /> */}
      {/* <Dialog
        // TransitionComponent={TransitionDialog}
        onClose={() => {}}
        open={transLoading}
        sx={{
          '& .MuiPaper-root': {
            width: '100%',
            height: '280px',
            maxWidth: '600px', // Set your width here
          },
        }}>
        <DialogContent sx={{ height: { xs: '100px', sm: '170px' } }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <div class="loader-custom loader-custom-white" style={{ margin: '0 auto', transform: 'translateY(50px)' }}></div>
            <Box
              sx={{
                paddingTop: '120px',
                // position: 'fixed',
                // top: { xs: 'calc(50% + 70px)', mob: 'calc(50% + 60px)' },
                // left: '50%',
                // transform: 'translate(-50%,-50%)',
                zIndex: '100000',
                textAlign: 'center',
                fontSize: { xs: '20px', mob: '22px' },
                whiteSpace: { xs: 'normal', mob: 'nowrap' },
                width: { xs: '262px', mob: 'auto' },
              }}>
              {locales[process.env.REACT_APP_LOCALE].yourDonateSending}
            </Box>
          </div>
        </DialogContent>
      </Dialog> */}
      <PaymentGameCardModal isBoost={watchBoost} open={openPaymentModal} packageId={selectedPackageId} onClose={handleClosePaymentCardModal} />
    </Box>
  );
};
export default GamePageComponent;

import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import ExitIcon from '@mui/icons-material/ExitToApp';
import AccountIcon from '@mui/icons-material/AccountCircle';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { purple, grey } from '@mui/material/colors';
import { shadows } from '@mui/system';
import { Badge, Container, Fab, Paper, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddCardIcon from '@mui/icons-material/AddCard';
import BuyModal from './BuyModal';
import SuccessModal from './SuccessModal';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../redux/slices/user.slice';
import { currencyFormat } from '../utils/currencyFormat';
import PaymentModal from './PaymentModal';
import ErrorModal from './ErrorModa';
import Loading from './Loading';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import { createTransactionReset } from '../redux/slices/transaction.slice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ResetPassModal from './ResetPassModal';
import GameButton from './GameButton';
import { AccountBalanceWalletOutlined, Close, PhotoCamera, RateReviewOutlined, SportsEsportsOutlined, GradeOutlined, SupportAgentOutlined, Person, ShoppingCartOutlined } from '@mui/icons-material';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';
import { locales } from '../locales';
import MenuBtn from './MenuBtn';
import Snowfall from 'react-snowfall';
import TopHeader from './TopHeader';
import { getTypePaymentList } from '../redux/slices/app.slice';
const drawerWidth = 240;
const navItems = ['Home', 'About', 'Contact'];

function HomeLayout(props) {
  const container = window !== undefined ? () => window().document.body : undefined;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    getUserState: { loading, data, error },
  } = useSelector((state) => state.user);
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [settingsData, setSettingsData] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/settings`).then((resp) => {
      setSettingsData(resp.data);
    });
    dispatch(getTypePaymentList());
  }, []);

  useEffect(() => {
    if (settingsData) {
      if (settingsData?.[0]?.activeWarning) {
        const warningDate = localStorage.getItem('warningTime');
        const warningDateMoment = moment(warningDate);
        if (warningDateMoment.isValid()) {
          if (moment().isAfter(warningDateMoment)) {
            setShowWarning(true);
          }
        } else {
          setShowWarning(true);
        }
      }
    }
  }, [settingsData]);
  const [openPay, setOpenPay] = useState(false);
  const handleClosePay = () => {
    setOpenPay(false);
  };
  const { cart } = useSelector((state) => state.user);
  return (
    <Paper sx={{ height: '100%' }}>
      {showWarning && (
        <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, background: '#555', padding: '30px', width: '100vw', zIndex: '1000000', color: 'white', textAlign: 'center', boxSizing: 'border-box' }}>
          <Box sx={{ maxWidth: '80%', margin: '0 auto', textAlign: 'center', lineHeight: '24px' }}>{settingsData?.[0]?.textWarning}</Box>
          <IconButton
            onClick={() => {
              setShowWarning(false);
              localStorage.setItem('warningTime', moment().add(3, 'hours').toDate());
            }}
            disableRipple
            sx={{ position: 'fixed', right: '10px', top: '5px' }}>
            <Close />
          </IconButton>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'scroll',
          '&::-webkit-scrollbar': {
            width: '0 !important',
            height: '0 !important',
          },
        }}>
        <AppBar
          component="nav"
          sx={{
            boxShadow: 0,
            backgroundColor: '#060606',
          }}>
          {' '}
          <TopHeader />
          <Toolbar variant="dense" sx={{ minHeight: { xs: '59px', mobile: '85px' } }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {' '}
                <Box sx={{ display: { mobile: 'none', xs: 'block' } }}>
                  <Button id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick} sx={{ px: '0', minWidth: '30px' }}>
                    <MenuIcon sx={{ fontSize: '30px' }} />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}>
                    <MenuItem onClick={() => navigate('/')}>{locales[process.env.REACT_APP_LOCALE].main}</MenuItem>
                    <MenuItem onClick={() => navigate('/account')}>{locales[process.env.REACT_APP_LOCALE].games}</MenuItem>
                    {process.env.REACT_APP_LOCALE != 'en' && <MenuItem onClick={() => navigate('/rating')}>{locales[process.env.REACT_APP_LOCALE].rating}</MenuItem>}
                    {process.env.REACT_APP_LOCALE != 'en' && <MenuItem onClick={() => navigate('/reviews')}> {locales[process.env.REACT_APP_LOCALE].reviews}</MenuItem>}
                    <MenuItem onClick={() => navigate('/support')}>{locales[process.env.REACT_APP_LOCALE].support}</MenuItem>
                  </Menu>
                </Box>
                <Box sx={{ display: { xs: 'none', mobile: 'block' } }}>
                  <Link to="/">
                    <img src="/logo.png" style={{ height: '70px', width: '90px', objectFit: 'cover', display: 'block' }} />
                  </Link>
                </Box>
              </Box>
              {/* <Box sx={{ display: { mobile: 'flex', xs: 'none' }, justifyContent: 'center', alignItems: 'center', mr: 1 }}>
                <Button disableRipple={true} onClick={() => navigate('/about')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, my: 2, color: 'white', display: 'block' }}>
                  {locales[process.env.REACT_APP_LOCALE].aboutService}
                </Button>
                {process.env.REACT_APP_LOCALE != 'en' && (
                  <Button
                    startIcon={<StarBorderOutlinedIcon sx={{ color: 'rgb(255, 215, 0)', marginRight: '-4px' }} />}
                    disableRipple={true}
                    onClick={() => navigate('/rating')}
                    sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, my: 2, color: 'white', display: 'flex', alignItems: 'center' }}>
                    {locales[process.env.REACT_APP_LOCALE].rating}
                  </Button>
                )}
                <Button disableRipple={true} onClick={() => navigate('/donate')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, my: 2, color: 'white', display: 'block' }}>
                  {locales[process.env.REACT_APP_LOCALE].instruction}
                </Button>{' '}
                <Button disableRipple={true} onClick={() => navigate('/faq')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, my: 2, color: 'white', display: 'block', minWidth: 0 }}>
                  {locales[process.env.REACT_APP_LOCALE].faq}
                </Button>
                {process.env.REACT_APP_LOCALE != 'en' && (
                  <Button disableRipple={true} onClick={() => navigate('/reviews')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, my: 2, color: 'white', display: 'block' }}>
                    {locales[process.env.REACT_APP_LOCALE].reviews}
                  </Button>
                )}
                <Button disableRipple={true} onClick={() => navigate('/guarante')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, my: 2, color: 'white', display: 'block' }}>
                  {locales[process.env.REACT_APP_LOCALE].guarantee}
                </Button>
                <Button disableRipple={true} onClick={() => navigate('/support')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, my: 2, color: 'white', display: 'block' }}>
                  {locales[process.env.REACT_APP_LOCALE].support}
                </Button>
              </Box> */}
              <Box>
                {!data && !loading && error ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '24px' }}>
                    <Button class="login-custom" disableRipple={true} onClick={() => navigate('/auth')} sx={{ '&:hover': { backgroundColor: 'transparent', transform: 'scale(0.78)' }, my: 2, color: 'white', display: 'block' }} variant="outlined">
                      <div></div>
                      <span> {locales[process.env.REACT_APP_LOCALE].enter}</span>
                    </Button>
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center', columnGap: { xs: '15px', mobile: '30px' } }}>
                    {' '}
                    <MenuBtn
                      customStyle={{
                        display: { mobile: 'none', xs: 'block' },
                        background: 'rgb(237, 188, 11)',
                      }}
                      tooltip={'Игры'}
                      onClick={() => {
                        navigate('/account');
                      }}>
                      <SportsEsportsOutlined sx={{ fontSize: '24px' }} />
                    </MenuBtn>
                    <GameButton />
                    <div style={{ height: '34px', width: '1px', backgroundColor: '#333' }}></div>
                    <Tooltip title={'Пополнить баланс'} placement="bottom">
                      <Box
                        onClick={() => {
                          setOpenPay(true);
                        }}
                        sx={{ cursor: 'pointer', userSelect: 'none', display: 'flex', alignItems: 'center', height: '35px', border: '1px solid #222222', backgroundColor: '#060606', borderRadius: '15px', padding: '0 14px' }}>
                        <AccountBalanceWalletOutlined sx={{ fontSize: '24px', marginRight: '10px' }} />
                        <Box sx={{ whiteSpace: 'nowrap', display: 'block', fontWeight: '400 !important', color: 'success.light' }}>{currencyFormat(data.balance)}</Box>

                        {/* <MenuBtn tooltip={'Корзина'}>
                          <div class={addedCart && 'cart-moving'}>
                            <Badge
                              onClick={handleOpenCart}
                              badgeContent={cart?.length || 0}
                              color="primary"
                              sx={{
                                cursor: 'pointer',
                                '& .MuiBadge-badge': { color: '#e7e1d8', backgroundColor: '#9c2628', fontWeight: '600 !important' },
                                '& svg': {
                                  fill: '#fff',
                                },
                              }}>
                              <ShoppingCartOutlined sx={{ fontSize: '24px' }} />
                            </Badge>
                          </div>
                        </MenuBtn> */}
                      </Box>
                    </Tooltip>
                    <MenuBtn tooltip={'Корзина'}>
                      <div>
                        <Badge
                          onClick={() => {
                            navigate('/cart');
                          }}
                          badgeContent={cart?.length || 0}
                          color="primary"
                          sx={{
                            cursor: 'pointer',
                            '& .MuiBadge-badge': { color: '#e7e1d8', backgroundColor: '#9c2628', fontWeight: '600 !important' },
                            '& svg': {
                              fill: '#fff',
                            },
                          }}>
                          <ShoppingCartOutlined sx={{ fontSize: '24px' }} />
                        </Badge>
                      </div>
                    </MenuBtn>
                    <Box
                      sx={{ cursor: 'pointer', display: { mobile: 'none', xs: 'block' } }}
                      onClick={() => {
                        navigate('/profile');
                      }}>
                      {data?.avatar ? (
                        <img style={{ width: '28px', height: '28px', objectFit: 'cover', borderRadius: '50%' }} src={`${process.env.REACT_APP_SERVER_DOMAIN}/images/${data?.avatar}`} />
                      ) : (
                        <Box sx={{ transition: 'all 0.3s', position: 'relative', width: '28px', height: '28px', borderRadius: '50%', border: `2px solid rgba(255, 255, 255,0.3)` }}>
                          <Person sx={{ fontSize: '20px', color: '#666', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', position: 'absolute', color: 'rgba(255, 255, 255,0.3)' }} />
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <PaymentModal open={openPay} typePay={1} onClose={handleClosePay} />
        <Box
          component="main"
          sx={{
            width: '100%',
            paddingTop: { xs: '59px', mobile: 'var(--header-height)' },
            ...(props.isWhite && { background: '#fff' }),
          }}>
          <Container style={{ ...(props.isFullContainer && { maxWidth: 'none', padding: 0 }), ...(props.isWhite && { background: '#fff' }) }}>
            <div className="">
              {props.children}
              {/* <Fab
                onClick={handleClickOpen}
                color="warning"
                aria-label="add"
                sx={{
                  position: 'fixed',
                  right: '20px',
                  bottom: '20px',
                }}>
                <AddIcon />
              </Fab> */}
            </div>
          </Container>
        </Box>

        <Footer />
      </Box>
    </Paper>
  );
}

export default HomeLayout;

import { Container } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router';
import HomeLayout from '../components/HomeLayout';
import DrawerAppBar from '../components/MainLayout';
import NotFound from '../components/NotFound';
import { findTypeGame, resetFindTypeGame } from '../redux/slices/typeGame.slice';
import { Helmet } from 'react-helmet';
import GamePageComponent from './GamePageComponents';
import { locales } from '../locales';
import Loader from '../components/Loader';

const GamePage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  let games = [
    {
      id: 1,
      name: locales[process.env.REACT_APP_LOCALE].game1Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game1BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game1Desc,
      helpImage: `/id_idv.jpg`,
      background: '/gameidv-bg.jpg',
      mobileBackground: '/m-gameidv-bg.jpg',
      checkPlayer: true,
      checkRequired: true,
      placeholder: '16852220',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      titleInput: 'Ваш игровой ID',
      switchTypeDonate: true,
      label: 'ID',
      helperText: 'Например 18140018',
      serverList: [
        {
          name: 'NA/EU',
          serverId: '2011',
        },
        {
          name: 'Asia',
          serverId: '2001',
        },
      ],
      pacakgeImageList: [`/game-img-1.png`, `/game-img-185.png`, `/game-img-2.png`, `/game-img-3.png`, `/game-img-4.png`, `/game-img-5.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game1Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game1Meta,
    },
    {
      id: 2,
      name: locales[process.env.REACT_APP_LOCALE].game2Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game2BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game2Desc,
      helpImage: `/id_genshin.jpg`,
      background: '/back-genshin.jpg',
      mobileBackground: '/m-genshin-bg.jpg',
      label: '',
      checkPlayer: false,
      checkRequired: true,
      titleInput: 'Ваш игровой UID',
      label: 'UID',
      helperText: 'Например 732884343',
      secondBlockText: 'Доставка ~0-10 мин',
      checkText: locales[process.env.REACT_APP_LOCALE].check,
      serverList: [
        {
          name: 'Europe',
          serverId: 'Europe',
        },
        {
          name: 'America',
          serverId: 'America',
        },
        {
          name: 'Asia',
          serverId: 'Asia',
        },
        {
          name: 'TW, HK, MO',
          serverId: 'TW, HK, MO',
        },
      ],
      pacakgeImageList: [`/game-img-6-1.png`, `/game-img-6.png`, `/game-img-7.png`, `/game-img-8.png`, `/game-img-9.png`, `/game-img-10.png`, `/game-img-11.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game2Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game2Meta,
    },
    {
      id: 3,
      name: locales[process.env.REACT_APP_LOCALE].game3Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game3BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game3Desc,
      helpImage: `/id_Onmyoji-Arena.jpg`,
      background: '/back-onmyoji-arena.jpg',
      mobileBackground: '/m-onmyoji-arena-bg.jpg',
      checkPlayer: true,
      checkRequired: true,
      titleInput: 'Ваш игровой ID',
      label: 'ID',
      helperText: 'Например 3053038521',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      serverList: [
        {
          name: 'Global',
          serverId: 'cs12',
        },
        {
          name: 'Asia',
          serverId: 'cs10',
        },
      ],
      pacakgeImageList: [`/game-img-3-1.png`, `/game-img-3-2.png`, `/game-img-3-3.png`, `/game-img-3-4.png`, `/game-img-3-5.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game3Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game3Meta,
    },
    {
      id: 4,
      name: locales[process.env.REACT_APP_LOCALE].game4Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game4BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game4Desc,
      helpImage: `/id-lotr-rise-to-war.jpg`,
      background: '/back-lotr-rise-to-war.jpg',
      mobileBackground: '/m-lotr-rise-to-war.jpg',
      checkPlayer: true,
      checkRequired: true,
      isSelectServer: true,
      titleInput: 'Ваш игровой ID',
      label: 'ID',
      helperText: 'Например 17409595',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,

      pacakgeImageList: [`/game-img-4-3.png`, `/game-img-4-4.png`, `/game-img-4-5.png`, `/game-img-4-6.png`, `/game-img-4-7.png`, `/game-img-4-1.png`, `/game-img-4-2.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game4Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game4Meta,
    },
    {
      id: 5,
      name: locales[process.env.REACT_APP_LOCALE].game5Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game5BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game5Desc,
      helpImage: `/id_ml.jpg`,
      background: '/back-ml.jpg',
      mobileBackground: '/m-ml-bg.jpg',
      checkPlayer: true,
      checkRequired: true,
      isSelectServer: true,
      labelZone: 'Zone ID',
      helperTextZone: 'Например 6442',
      titleInput: 'Игровой ID',
      label: 'ID',
      helperText: 'Например 1192168200',
      secondBlockText: 'Доставка ~0-10 мин',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      serverList: false,
      pacakgeImageList: [`/game-img-twilight-pass.png`, `/game-img-ml-1.png`, `/game-img-ml-2.png`, `/game-img-ml-3.png`, `/game-img-ml-4.png`, `/game-img-ml-5.png`, `/game-img-ml-6.png`, `/game-img-ml-7.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game5Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game5Meta,
    },
    {
      id: 6,
      name: locales[process.env.REACT_APP_LOCALE].game6Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game6BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game6Desc,
      helpImage: `/id-pubg.jpg`,
      background: '/back-pubg.jpg',
      mobileBackground: '/m-pubg.jpg',
      checkPlayer: false,
      checkRequired: false,
      isSelectServer: true,
      noServer: true,
      titleInput: 'Игровой ID',
      label: 'ID',
      helperText: 'Например 51844564796',
      secondBlockText: 'Доставка ~0-10 мин',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,

      pacakgeImageList: [`/game-img-6-01.png`, `/game-img-6-2.png`, `/game-img-6-3.png`, `/game-img-6-4.png`, `/game-img-6-5.png`, `/game-img-6-6.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game6Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game6Meta,
    },
    {
      id: 7,
      name: locales[process.env.REACT_APP_LOCALE].game7Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game7BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game7Desc,
      helpImage: `/id-pubg.jpg`,
      background: '/back-clash-of-clans.jpg',
      mobileBackground: '/m-pubg.jpg',
      checkPlayer: false,
      checkRequired: false,
      isSelectServer: true,
      noServer: true,
      checkPlayerString: true,
      titleInput: 'Игровой тег ID',
      label: 'Player Tag',
      helperText: 'Например #2R907RQLU',
      secondBlockText: 'Доставка ~0-10 мин',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,

      pacakgeImageList: [`/game-img-7-01.png`, `/game-img-7-02.png`, `/game-img-7-03.png`, `/game-img-7-04.png`, `/game-img-7-05.png`, `/game-img-7-06.png`, `/game-img-7-07.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game7Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game7Meta,
    },
    {
      id: 8,
      name: locales[process.env.REACT_APP_LOCALE].game8Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game8BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game8Desc,
      helpImage: `/id-pubg.jpg`,
      background: '/back-clash-royale.jpg',
      mobileBackground: '/m-pubg.jpg',
      checkPlayer: false,
      checkRequired: false,
      isSelectServer: true,
      noServer: true,
      checkPlayerString: true,
      titleInput: 'Игровой тег ID',
      label: 'Player Tag',
      helperText: 'Например #2R907RQLU',
      secondBlockText: 'Доставка ~0-10 мин',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,

      pacakgeImageList: [`/game-img-8-01.png`, `/game-img-8-02.png`, `/game-img-8-03.png`, `/game-img-8-04.png`, `/game-img-8-05.png`, `/game-img-8-06.png`, `/game-img-8-07.png`, `/game-img-8-08.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game8Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game8Meta,
    },
    {
      id: 9,
      name: locales[process.env.REACT_APP_LOCALE].game9Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game9BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game9Desc,
      helpImage: ``,
      background: '/back-brawl-stars.jpg',
      mobileBackground: '/m-pubg.jpg',
      checkPlayer: false,
      checkRequired: false,
      isSelectServer: true,
      noServer: true,
      checkPlayerString: true,
      titleInput: 'Игровой тег ID',
      label: 'Player Tag',
      helperText: 'Например #2R907RQLU',
      secondBlockText: 'Доставка ~0-10 мин',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,

      pacakgeImageList: [`/brawl-stars-0.png`, `/brawl-stars-1.png`, `/brawl-stars-2.png`, `/brawl-stars-3.png`, `/brawl-stars-4.png`, `/brawl-stars-5.png`, `/brawl-stars-6.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game9Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game9Meta,
    },
    {
      id: 10,
      // name: locales[process.env.REACT_APP_LOCALE].game10Name,
      name: 'Пополнение Steam',

      // desc: locales[process.env.REACT_APP_LOCALE].game10Desc,
      desc: 'Пополнение вашего Steam аккаунта быстро и безопасно, только по логину. Любой регион. Максимальное пополнение за раз 200$. Количество пополнений безлимитное. Чтобы сделать расчет, добавьте к сумме пополнения 29%. Данная комиссия включает: комиссия платежной системы на ввод и ввывод, конвертацию валюты, комиссия провайдера Steam, наша комиссия.',
      helpImage: `/id-pubg.jpg`,
      mobileBackground: '/m-pubg.jpg',
      checkPlayer: false,
      checkRequired: false,
      isSelectServer: true,
      noServer: true,
      noCheckPlayer: true,
      checkText: locales[process.env.REACT_APP_LOCALE].accept,

      pacakgeImageList: [`/steam-img.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game10Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game10Meta,
    },
    {
      id: 11,
      name: locales[process.env.REACT_APP_LOCALE].game11Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game11BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game11Desc,
      helpImage: `/id-pubg.jpg`,
      background: '/hsr-back.jpg',
      mobileBackground: '/m-pubg.jpg',
      checkPlayer: false,
      checkRequired: true,
      titleInput: 'Ваш игровой UID',
      label: 'UID',
      helperText: 'Например 732884343',
      secondBlockText: 'Доставка ~0-10 мин',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      pacakgeImageList: [`/hsr-img-00.png`, `/hsr-img-01.png`, `/hsr-img-01.png`, `/hsr-img-01.png`, `/hsr-img-01.png`, `/hsr-img-01.png`, `/hsr-img-01.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game11Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game11Meta,
      serverList: [
        {
          name: 'Europe',
          serverId: 'Europe',
        },
        {
          name: 'America',
          serverId: 'America',
        },
        {
          name: 'Asia',
          serverId: 'Asia',
        },
        {
          name: 'TW, HK, MO',
          serverId: 'TW, HK, MO',
        },
      ],
    },
    {
      id: 12,
      name: locales[process.env.REACT_APP_LOCALE].game12Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game12BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game12Desc,
      background: '/game-hp-bg.jpg',
      checkPlayer: true,
      checkRequired: true,
      titleInput: 'Ваш игровой ID',
      label: 'ID',
      helperText: 'Например 000056181',
      secondBlockText: 'Моментальная доставка',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      isSelectServer: true,
      switchTypeDonate: true,
      serverList: [
        {
          name: 'Thunderbird',
          serverId: '13001',
        },
        {
          name: '不死鳥',
          serverId: '13011',
        },
        {
          name: 'ユニコーン',
          serverId: '13012',
        },
        {
          name: '니플러',
          serverId: '13013',
        },
        {
          name: 'Ashwinder',
          serverId: '13014',
        },
        {
          name: 'Erumpent',
          serverId: '13015',
        },
        {
          name: 'Sphinx',
          serverId: '13016',
        },
        {
          name: 'Rougarou',
          serverId: '13017',
        },
      ],
      pacakgeImageList: [`/hp-img-1.png`, `/hp-img-2.png`, `/hp-img-3.png`, `/hp-img-4.png`, `/hp-img-5.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game12Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game12Meta,
    },
    {
      id: 13,
      name: locales[process.env.REACT_APP_LOCALE].game13Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game13BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game13Desc,
      background: '/la-back.jpg',
      checkPlayer: true,
      checkRequired: true,
      titleInput: 'Ваш игровой ID',
      label: 'ID',
      helperText: 'Например 24110178',
      secondBlockText: 'Моментальная доставка',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      isSelectServer: true,
      switchTypeDonate: true,
      serverList: [
        { serverId: '500001', name: 'MiskaTown (NA)' },
        { serverId: '500002', name: 'SandCastle (NA)' },
        { serverId: '500003', name: 'MouthSwamp (NA)' },
        { serverId: '500004', name: 'RedwoodTown (NA)' },
        { serverId: '500005', name: 'Obelisk (NA)' },
        { serverId: '500006', name: 'NewLand (NA)' },
        { serverId: '500007', name: 'ChaosOutpost (NA)' },
        { serverId: '500008', name: 'IronStride (NA)' },
        { serverId: '500009', name: 'CrystalthornSea (NA)' },
        { serverId: '510001', name: 'FallForest (AU)' },
        { serverId: '510002', name: 'MountSnow (AU)' },
        { serverId: '520001', name: 'NancyCity (SEA)' },
        { serverId: '520002', name: 'CharlesTown (SEA)' },
        { serverId: '520003', name: 'SnowHighlands (SEA)' },
        { serverId: '520004', name: 'Santopany (SEA)' },
        { serverId: '520005', name: 'LevinCity (SEA)' },
        { serverId: '520006', name: 'MileStone (SEA)' },
        { serverId: '520007', name: 'ChaosCity (SEA)' },
        { serverId: '520008', name: 'TwinIslands (SEA)' },
        { serverId: '520009', name: 'HopeWall (SEA)' },
        { serverId: '520010', name: 'LabyrinthSea (SEA)' },
      ],
      pacakgeImageList: [`/la-img-1.png`, `/la-img-2.png`, `/la-img-3.png`, `/la-img-4.png`, `/la-img-5.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game13Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game13Meta,
    },
    {
      id: 14,
      name: locales[process.env.REACT_APP_LOCALE].game14Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game14BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game14Desc,
      background: '/ko-back.jpg',
      checkPlayer: true,
      checkRequired: true,
      titleInput: 'Ваш игровой ID',
      label: 'ID',
      helperText: 'Например 3084878143',
      secondBlockText: 'Моментальная доставка',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      isSelectServer: true,
      switchTypeDonate: true,
      serverList: [
        {
          name: 'Battle Royale (Global)',
          serverId: '10001',
        },
        {
          name: 'Battle Royale (NA)',
          serverId: '10006',
        },
        {
          name: '荒野行动 （JP)',
          serverId: '10007',
        },
        {
          name: 'PC- Asia',
          serverId: '10010',
        },
        {
          name: 'PC-America',
          serverId: '10011',
        },
        {
          name: 'PC-Japan',
          serverId: '10012',
        },
      ],
      pacakgeImageList: [`/ko-img-1.png`, `/ko-img-2.png`, `/ko-img-3.png`, `/ko-img-4.png`, `/ko-img-5.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game14Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game14Meta,
    },
    {
      id: 15,
      name: locales[process.env.REACT_APP_LOCALE].game15Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game15BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game15Desc,
      background: '/ar-back.jpg',
      checkPlayer: true,
      checkRequired: true,
      titleInput: 'Ваш игровой ID',
      label: 'ID',
      helperText: 'Например 234795',
      secondBlockText: 'Моментальная доставка',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      isSelectServer: true,
      switchTypeDonate: true,
      serverList: [
        {
          name: 'JAPAN',
          serverId: '10001',
        },
        {
          name: 'KOREA',
          serverId: '10011',
        },
        {
          name: 'ASIA',
          serverId: '10021',
        },
        {
          name: 'NORTH AMERICA',
          serverId: '10031',
        },
        {
          name: 'EUROPE',
          serverId: '10041',
        },
      ],
      pacakgeImageList: [`/ar-img-1.png`, `/ar-img-2.png`, `/ar-img-3.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game15Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game15Meta,
    },
    {
      id: 16,
      name: locales[process.env.REACT_APP_LOCALE].game16Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game16BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game16Desc,
      background: '/il-back.jpg',
      checkPlayer: true,
      checkRequired: true,
      titleInput: 'Ваш игровой ID',
      label: 'ID',
      helperText: 'Например 20159700380',
      secondBlockText: 'Моментальная доставка',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      isSelectServer: true,
      switchTypeDonate: true,
      serverList: [
        {
          name: 'Infinite Lagrange',
          serverId: '1',
        },
      ],
      pacakgeImageList: [`/il-img-1.png`, `/il-img-2.png`, `/il-img-3.png`, `/il-img-4.png`, `/il-img-5.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game16Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game16Meta,
    },
    {
      id: 17,
      name: locales[process.env.REACT_APP_LOCALE].game17Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game17BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game17Desc,
      background: '/dbdm-back.jpg',
      checkPlayer: true,
      checkRequired: true,
      titleInput: 'Ваш игровой ID',
      label: 'ID',
      helperText: 'Например 965424548',
      secondBlockText: 'Моментальная доставка',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      isSelectServer: true,
      switchTypeDonate: true,
      serverList: [
        {
          name: 'Southeast Asia',
          serverId: '10001',
        },
        {
          name: 'HMT',
          serverId: '10002',
        },
        {
          name: 'Japan',
          serverId: '10003',
        },
        {
          name: 'Korea',
          serverId: '10004',
        },
      ],
      pacakgeImageList: [`/dbd-img-1.png`, `/dbd-img-2.png`, `/dbd-img-3.png`, `/dbd-img-4.png`, `/dbd-img-5.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game17Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game17Meta,
    },
    {
      id: 18,
      name: locales[process.env.REACT_APP_LOCALE].game18Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game18BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game18Desc,
      background: '/arena-breakout-wp.jpg',
      checkPlayer: false,
      checkRequired: false,
      noServer: true,
      titleInput: 'Ваш игровой ID',
      label: 'ID',
      helperText: 'Например 3583216356',
      secondBlockText: 'Моментальная доставка',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      isSelectServer: false,
      switchTypeDonate: false,
      serverList: [],
      pacakgeImageList: [
        `/arena-breakout-bonds-1.png`,
        `/arena-breakout-bonds-2.png`,
        `/arena-breakout-bonds-3.png`,
        `/arena-breakout-bonds-4.png`,
        `/arena-breakout-bonds-5.png`,
        `/arena-breakout-bonds-6.png`,
        `/arena-breakout-premium-battle-pass.png`,
        `/arena-breakout-advanced-battle-pass.png`,
        `/arena-breakout-elite-trial.png`,
        `/arena-breakout-bulletproof-case.png`,
        `/arena-breakout-composite-case.png`,
        `/arena-breakout-begginer-select.png`,
      ],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game18Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game18Meta,
    },
    {
      id: 19,
      name: locales[process.env.REACT_APP_LOCALE].game19Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game19BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game19Desc,
      background: '/dbdm-back.jpg',
      checkPlayer: false,
      checkRequired: false,
      noServer: false,
      titleInput: 'Ваш игровой ID',
      titleServerInput: 'Ник в игре',
      label: 'ID',
      helperText: 'Например 965424548',
      secondBlockText: 'Моментальная доставка',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      isSelectServer: true,
      switchTypeDonate: false,
      serverList: false,
      pacakgeImageList: [`/dbd-img-1.png`, `/dbd-img-2.png`, `/dbd-img-3.png`, `/dbd-img-4.png`, `/dbd-img-5.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game19Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game19Meta,
    },
    {
      id: 20,
      name: locales[process.env.REACT_APP_LOCALE].game20Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game20BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game20Desc,
      background: '/dbdm-back.jpg',
      checkPlayer: false,
      checkRequired: false,
      noServer: false,
      titleInput: 'Ваш игровой ID',
      label: 'ID',
      helperText: 'Например 965424548',
      secondBlockText: 'Моментальная доставка',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      isSelectServer: true,
      switchTypeDonate: false,
      serverList: [
        { serverId: 'Asia Pacific - Eden', name: 'Asia Pacific - Eden' },
        { serverId: 'Asia Pacific - Nova', name: 'Asia Pacific - Nova' },
        { serverId: 'Asia Pacific - Ruby', name: 'Asia Pacific - Ruby' },
        { serverId: 'Asia Pacific - Babel', name: 'Asia Pacific - Babel' },
        { serverId: 'Asia Pacific - Fate', name: 'Asia Pacific - Fate' },
        { serverId: 'Asia Pacific - Gomap', name: 'Asia Pacific - Gomap' },
        { serverId: 'Asia Pacific - Pluto', name: 'Asia Pacific - Pluto' },
        { serverId: 'Asia Pacific - Sushi', name: 'Asia Pacific - Sushi' },
        { serverId: 'Asia Pacific - Venus', name: 'Asia Pacific - Venus' },
        { serverId: 'Asia Pacific - Galaxy', name: 'Asia Pacific - Galaxy' },
        { serverId: 'Asia Pacific - Memory', name: 'Asia Pacific - Memory' },
        { serverId: 'Asia Pacific - Oxygen', name: 'Asia Pacific - Oxygen' },
        { serverId: 'Asia Pacific - Sakura', name: 'Asia Pacific - Sakura' },
        { serverId: 'Asia Pacific - Seeker', name: 'Asia Pacific - Seeker' },
        { serverId: 'Asia Pacific - Shinya', name: 'Asia Pacific - Shinya' },
        { serverId: 'Asia Pacific - Stella', name: 'Asia Pacific - Stella' },
        { serverId: 'Asia Pacific - Uranus', name: 'Asia Pacific - Uranus' },
        { serverId: 'Asia Pacific - Utopia', name: 'Asia Pacific - Utopia' },
        { serverId: 'Asia Pacific - Jupiter', name: 'Asia Pacific - Jupiter' },
        { serverId: 'Asia Pacific - Sweetie', name: 'Asia Pacific - Sweetie' },
        { serverId: 'Asia Pacific - Atlantis', name: 'Asia Pacific - Atlantis' },
        { serverId: 'Asia Pacific - Daybreak', name: 'Asia Pacific - Daybreak' },
        { serverId: 'Asia Pacific - Takoyaki', name: 'Asia Pacific - Takoyaki' },
        { serverId: 'Asia Pacific - Adventure', name: 'Asia Pacific - Adventure' },
        { serverId: 'Asia Pacific - Yggdrasil', name: 'Asia Pacific - Yggdrasil' },
        { serverId: 'Asia Pacific - Cocoaiteruyo', name: 'Asia Pacific - Cocoaiteruyo' },
        { serverId: 'Asia Pacific - Food Fighter', name: 'Asia Pacific - Food Fighter' },
        { serverId: 'Asia Pacific - Mars', name: 'Asia Pacific - Mars' },
        { serverId: 'Asia Pacific - Vega', name: 'Asia Pacific - Vega' },
        { serverId: 'Asia Pacific - Neptune', name: 'Asia Pacific - Neptune' },
        { serverId: 'Asia Pacific - Tenpura', name: 'Asia Pacific - Tenpura' },
        { serverId: 'Asia Pacific - Moon', name: 'Asia Pacific - Moon' },
        { serverId: 'Asia Pacific - Mihashira', name: 'Asia Pacific - Mihashira' },
        { serverId: 'Asia Pacific - Cocokonderu', name: 'Asia Pacific - Cocokonderu' },
        { serverId: 'Europe - Aimanium', name: 'Europe - Aimanium' },
        { serverId: 'Europe - Alintheus', name: 'Europe - Alintheus' },
        { serverId: 'Europe - Andoes', name: 'Europe - Andoes' },
        { serverId: 'Europe - Anomora', name: 'Europe - Anomora' },
        { serverId: 'Europe - Astora', name: 'Europe - Astora' },
        { serverId: 'Europe - Valstamm', name: 'Europe - Valstamm' },
        { serverId: 'Europe - Blumous', name: 'Europe - Blumous' },
        { serverId: 'Europe - Celestialrise', name: 'Europe - Celestialrise' },
        { serverId: 'Europe - Cosmos', name: 'Europe - Cosmos' },
        { serverId: 'Europe - Dyrnwyn', name: 'Europe - Dyrnwyn' },
        { serverId: 'Europe - Elypium', name: 'Europe - Elypium' },
        { serverId: 'Europe - Excalibur', name: 'Europe - Excalibur' },
        { serverId: 'Europe - Espoir IV', name: 'Europe - Espoir IV' },
        { serverId: 'Europe - Estrela', name: 'Europe - Estrela' },
        { serverId: 'Europe - Ether', name: 'Europe - Ether' },
        { serverId: 'Europe - Ex Nihilor', name: 'Europe - Ex Nihilor' },
        { serverId: 'Europe - Futuria', name: 'Europe - Futuria' },
        { serverId: 'Europe - Hephaestus', name: 'Europe - Hephaestus' },
        { serverId: 'Europe - Midgard', name: 'Europe - Midgard' },
        { serverId: 'Europe - Iter', name: 'Europe - Iter' },
        { serverId: 'Europe - Kuura', name: 'Europe - Kuura' },
        { serverId: 'Europe - Lycoris', name: 'Europe - Lycoris' },
        { serverId: 'Europe - Lyramiel', name: 'Europe - Lyramiel' },
        { serverId: 'Europe - Magenta', name: 'Europe - Magenta' },
        { serverId: 'Europe - Magia Przygoda Aida', name: 'Europe - Magia Przygoda Aida' },
        { serverId: 'Europe - Olivine', name: 'Europe - Olivine' },
        { serverId: 'Europe - Omnium Prime', name: 'Europe - Omnium Prime' },
        { serverId: 'Europe - Turmus', name: 'Europe - Turmus' },
        { serverId: 'Europe - Transport Hub', name: 'Europe - Transport Hub' },
        { serverId: 'Europe - The Lumina', name: 'Europe - The Lumina' },
        { serverId: 'Europe - Seaforth Dock', name: 'Europe - Seaforth Dock' },
        { serverId: 'Europe - Slivercoast Lab', name: 'Europe - Slivercoast Lab' },
        { serverId: 'Europe - Karst Cave', name: 'Europe - Karst Cave' },
        { serverId: 'North America - The Glades', name: 'North America - The Glades' },
        { serverId: 'North America - Nightfall', name: 'North America - Nightfall' },
        { serverId: 'North America - Frontier', name: 'North America - Frontier' },
        { serverId: 'North America - Libera', name: 'North America - Libera' },
        { serverId: 'North America - Solaris', name: 'North America - Solaris' },
        { serverId: 'North America - Freedom-Oasis', name: 'North America - Freedom-Oasis' },
        { serverId: 'North America - The Worlds Between', name: 'North America - The Worlds Between' },
        { serverId: 'North America - Radiant', name: 'North America - Radiant' },
        { serverId: 'North America - Tempest', name: 'North America - Tempest' },
        { serverId: 'North America - New Era', name: 'North America - New Era' },
        { serverId: 'North America - Observer', name: 'North America - Observer' },
        { serverId: 'North America - Lunalite', name: 'North America - Lunalite' },
        { serverId: 'North America - Starlight', name: 'North America - Starlight' },
        { serverId: 'North America - Myriad', name: 'North America - Myriad' },
        { serverId: 'North America - Lighthouse', name: 'North America - Lighthouse' },
        { serverId: 'North America - Oumuamua', name: 'North America - Oumuamua' },
        { serverId: 'North America - Eternium Phantasy', name: 'North America - Eternium Phantasy' },
        { serverId: 'North America - Sol-lll', name: 'North America - Sol-lll' },
        { serverId: 'North America - Silver Bridge', name: 'North America - Silver Bridge' },
        { serverId: 'North America - Azure Plane', name: 'North America - Azure Plane' },
        { serverId: 'North America - Nirvana', name: 'North America - Nirvana' },
        { serverId: 'North America - Ozera', name: 'North America - Ozera' },
        { serverId: 'North America - Polar', name: 'North America - Polar' },
        { serverId: 'South America - Orion', name: 'South America - Orion' },
        { serverId: 'South America - Luna Azul', name: 'South America - Luna Azul' },
        { serverId: 'South America - Tiamat', name: 'South America - Tiamat' },
        { serverId: 'South America - Hope', name: 'South America - Hope' },
        { serverId: 'South America - Tanzanite', name: 'South America - Tanzanite' },
        { serverId: 'South America - Calodesma Seven', name: 'South America - Calodesma Seven' },
        { serverId: 'South America - Antlia', name: 'South America - Antlia' },
        { serverId: 'South America - Pegasus', name: 'South America - Pegasus' },
        { serverId: 'South America - Phoenix', name: 'South America - Phoenix' },
        { serverId: 'South America - Centaurus', name: 'South America - Centaurus' },
        { serverId: 'South America - Cepheu', name: 'South America - Cepheu' },
        { serverId: 'South America - Columba', name: 'South America - Columba' },
        { serverId: 'South America - Corvus', name: 'South America - Corvus' },
        { serverId: 'South America - Cygnus', name: 'South America - Cygnus' },
        { serverId: 'South America - Grus', name: 'South America - Grus' },
        { serverId: 'South America - Hydra', name: 'South America - Hydra' },
        { serverId: 'South America - Lyra', name: 'South America - Lyra' },
        { serverId: 'South America - Ophiuchus', name: 'South America - Ophiuchus' },
        { serverId: 'South America - Pyxis', name: 'South America - Pyxis' },
        { serverId: 'Southeast Asia - Phantasia', name: 'Southeast Asia - Phantasia' },
        { serverId: 'Southeast Asia - Mechafield', name: 'Southeast Asia - Mechafield' },
        { serverId: 'Southeast Asia - Ethereal Dream', name: 'Southeast Asia - Ethereal Dream' },
        { serverId: 'Southeast Asia - Odyssey', name: 'Southeast Asia - Odyssey' },
        { serverId: 'Southeast Asia - Aestral-Noa', name: 'Southeast Asia - Aestral-Noa' },
        { serverId: 'Southeast Asia - Osillron', name: 'Southeast Asia - Osillron' },
        { serverId: 'Southeast Asia - Chandra', name: 'Southeast Asia - Chandra' },
        { serverId: 'Southeast Asia - Saeri', name: 'Southeast Asia - Saeri' },
        { serverId: 'Southeast Asia - Aeria', name: 'Southeast Asia - Aeria' },
        { serverId: 'Southeast Asia - Scarlet', name: 'Southeast Asia - Scarlet' },
        { serverId: 'Southeast Asia - Gumi Gumi', name: 'Southeast Asia - Gumi Gumi' },
        { serverId: 'Southeast Asia - Fantasia', name: 'Southeast Asia - Fantasia' },
        { serverId: 'Southeast Asia - Oryza', name: 'Southeast Asia - Oryza' },
        { serverId: 'Southeast Asia - Stardust', name: 'Southeast Asia - Stardust' },
        { serverId: 'Southeast Asia - Arcania', name: 'Southeast Asia - Arcania' },
        { serverId: 'Southeast Asia - Animus', name: 'Southeast Asia - Animus' },
        { serverId: 'Southeast Asia - Mistilteinn', name: 'Southeast Asia - Mistilteinn' },
        { serverId: 'Southeast Asia - Valhalla', name: 'Southeast Asia - Valhalla' },
        { serverId: 'Southeast Asia - Illyrians', name: 'Southeast Asia - Illyrians' },
        { serverId: 'Southeast Asia - Florione', name: 'Southeast Asia - Florione' },
        { serverId: 'Southeast Asia - Oneiros', name: 'Southeast Asia - Oneiros' },
        { serverId: 'Southeast Asia - Famtosyana', name: 'Southeast Asia - Famtosyana' },
        { serverId: 'Southeast Asia - Edenia', name: 'Southeast Asia - Edenia' },
      ],
      pacakgeImageList: [`/dbd-img-1.png`, `/dbd-img-2.png`, `/dbd-img-3.png`, `/dbd-img-4.png`, `/dbd-img-5.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game20Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game20Meta,
    },
    {
      id: 21,
      name: locales[process.env.REACT_APP_LOCALE].game21Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game21BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game21Desc,
      background: '/dbdm-back.jpg',
      checkPlayer: false,
      checkRequired: false,
      noServer: true,
      titleInput: 'Ваш игровой ID',
      label: 'ID',
      helperText: 'Например 965424548',
      secondBlockText: 'Моментальная доставка',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      isSelectServer: false,
      switchTypeDonate: false,
      checkPlayerString: true,
      serverList: false,
      pacakgeImageList: [`/dbd-img-1.png`, `/dbd-img-2.png`, `/dbd-img-3.png`, `/dbd-img-4.png`, `/dbd-img-5.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game21Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game21Meta,
    },
    {
      id: 22,
      name: locales[process.env.REACT_APP_LOCALE].game22Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game22BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game22Desc,
      background: '/dbdm-back.jpg',
      checkPlayer: true,
      checkRequired: true,
      noServer: true,
      titleInput: 'Ваш игровой ID',
      label: 'ID',
      helperText: 'Например 965424548',
      secondBlockText: 'Моментальная доставка',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      isSelectServer: false,
      switchTypeDonate: true,
      serverList: false,
      pacakgeImageList: [`/dbd-img-1.png`, `/dbd-img-2.png`, `/dbd-img-3.png`, `/dbd-img-4.png`, `/dbd-img-5.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game22Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game22Meta,
    },
    {
      id: 23,
      name: locales[process.env.REACT_APP_LOCALE].game23Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game23BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game23Desc,
      background: '/dbdm-back.jpg',
      checkPlayer: false,
      checkRequired: false,
      noServer: true,
      titleInput: 'Ваш игровой ID',
      label: 'ID',
      helperText: 'Например 965424548',
      secondBlockText: 'Моментальная доставка',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      isSelectServer: false,
      switchTypeDonate: false,
      serverList: false,
      pacakgeImageList: [`/dbd-img-1.png`, `/dbd-img-2.png`, `/dbd-img-3.png`, `/dbd-img-4.png`, `/dbd-img-5.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game23Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game23Meta,
    },
    {
      id: 24,
      name: locales[process.env.REACT_APP_LOCALE].game24Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game24BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game24Desc,
      background: '/dbdm-back.jpg',
      checkPlayer: true,
      checkRequired: true,
      noServer: true,
      titleInput: 'Ваш игровой ID',
      label: 'ID',
      helperText: 'Например 965424548',
      secondBlockText: 'Моментальная доставка',
      checkText: locales[process.env.REACT_APP_LOCALE].accept,
      isSelectServer: false,
      switchTypeDonate: false,
      serverList: false,
      pacakgeImageList: [`/dbd-img-1.png`, `/dbd-img-2.png`, `/dbd-img-3.png`, `/dbd-img-4.png`, `/dbd-img-5.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game24Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game24Meta,
    },
    {
      id: 25,
      name: locales[process.env.REACT_APP_LOCALE].game25Name,
      bottomDesc: locales[process.env.REACT_APP_LOCALE].game25BottomDesc,
      desc: locales[process.env.REACT_APP_LOCALE].game25Desc,
      helpImage: `/id_genshin.jpg`,
      background: '/zenless-zone-zero-back.jpg',
      mobileBackground: '/m-genshin-bg.jpg',
      label: '',
      checkPlayer: false,
      checkRequired: false,
      titleInput: 'Ваш игровой UID',
      label: 'UID',
      helperText: 'Например 1500013356',
      secondBlockText: 'Доставка ~0-10 мин',
      checkText: locales[process.env.REACT_APP_LOCALE].check,
      serverList: [
        {
          name: 'Europe',
          serverId: 'Europe',
        },
        {
          name: 'America',
          serverId: 'America',
        },
        {
          name: 'Asia',
          serverId: 'Asia',
        },
        {
          name: 'TW, HK, MO',
          serverId: 'TW, HK, MO',
        },
      ],
      pacakgeImageList: [`/zenless-zone-zero-1.png`, `/zenless-zone-zero-2.png`, `/zenless-zone-zero-3.png`, `/zenless-zone-zero-4.png`, `/zenless-zone-zero-6.png`, `/zenless-zone-zero-6.png`, `/zenless-zone-zero-7.png`],
      privacyContent: locales[process.env.REACT_APP_LOCALE].game25Privacy,
      metaTags: locales[process.env.REACT_APP_LOCALE].game25Meta,
    },
  ];

  const [gameList, setGameList] = useState([]);
  const [dataPage, setDataPage] = useState(null);
  useEffect(() => {
    return () => {
      setGameList([]);
      setDataPage(null);
      dispatch(resetFindTypeGame());
    };
  }, []);

  const {
    findTypeGameState: { data: dataFindTypeGame, error: errorFindTypeGame },
  } = useSelector((state) => state.typeGame);
  console.log(errorFindTypeGame);
  useEffect(() => {
    if (id) {
      dispatch(findTypeGame(id));
    }
  }, [id]);
  useEffect(() => {
    if (dataFindTypeGame && !errorFindTypeGame) {
      const findGame = games?.find((gameItem) => gameItem?.id === dataFindTypeGame?.id);
      if (findGame) {
        if (findGame?.id === 4) {
          axios.get(`${process.env.REACT_APP_SERVER_URL}/servers/game-4`).then((res) => {
            let serverListGame4 = res.data;
            games[3].serverList = serverListGame4;
            setGameList(games);
            setDataPage({ ...findGame, fullName: dataFindTypeGame?.name });
          });
        } else {
          setGameList(games);
          setDataPage({ ...findGame, fullName: dataFindTypeGame?.name });
        }
      }
    }
  }, [dataFindTypeGame]);

  return (
    <>
      {errorFindTypeGame ? (
        <NotFound />
      ) : (
        <>
          {gameList?.length !== 0 && dataPage ? (
            <>{dataFindTypeGame && dataPage ? <GamePageComponent data={dataPage} /> : <div class="loader-custom" style={{ position: 'fixed', top: '43%', left: '50%', transform: 'translate(-50%,-50%)' }}></div>}</>
          ) : (
            <Loader noOverlay size={50} style={{ position: 'fixed', top: '43%', left: 'calc(50% - 20px)', transform: 'translate(-50%,-50%)' }} />
            // <div class="loader-custom" style={{ position: 'fixed', top: '43%', left: '50%', transform: 'translate(-50%,-50%)' }}></div>
          )}
        </>
      )}
    </>
  );
};
export default GamePage;
